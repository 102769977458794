<template>
  <b-modal id="modalbasic" size="lg" ref="modalbasic" @hide="close()" v-model="isVisible" :title="this.title"
    :no-close-on-backdrop="true" @cancel='close()'>
    <b-row>
      <b-col xxs="12" lg="12" xl="12" class="pr-6 display">
        <b-card class="mb-4 mr-8">
          <b-form
            @submit.stop.prevent="onValidateAffiliateFormSubmit"
            class="av-tooltip tooltip-label-top"
          >
          <b-row>
              <b-colxx sm="6">
                <b-form-group label="Affiliate Type">
                  <multiselect
                    :disabled="true"
                    v-model="affiliate.types"
                    :options="affiliateTypes"
                    :multiple="true"
                    :close-on-select="false"
                    placeholder="Select Affiliate Type"
                    track-by="affiliateTypeID"
                    :custom-label="affiliateCustomLabel"
                  ></multiselect>
                </b-form-group>
              </b-colxx>
              <b-col sm="6">
                <b-form-group label="Active">
                  <switches
                    :disabled="true"
                    v-model="affiliate.active"
                    theme="custom"
                    color="primary"
                  ></switches>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-colxx sm="6">
                <b-form-group label="Company Name">
                  <b-form-input
                    :disabled="true"
                    v-model.trim="affiliate.companyName"
                    rows="3"
                  ></b-form-input>
                </b-form-group>
              </b-colxx>
              <b-col sm="6">
                <b-form-group label="Corporate Customer">
                  <switches
                    :disabled="true"
                    v-model="affiliate.corporateCustomer"
                    theme="custom"
                    color="primary"
                  ></switches>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-colxx sm="6">
                <b-form-group label="Street Address 1">
                  <b-form-input
                    :disabled="true"
                    v-model.trim="affiliate.streetAddress"
                    id="map"
                    classname="form-control"
                    country="us">
                  </b-form-input>
                </b-form-group>
              </b-colxx>
              <b-colxx sm="6">
                <b-form-group label="Street Address 2">
                  <b-form-input
                    :disabled="true"
                    v-model.trim="affiliate.streetAddress2"
                    rows="3"
                  ></b-form-input>
                </b-form-group>
              </b-colxx>
            </b-row>
            <b-row>
              <b-colxx sm="6">
                <b-form-group label="City">
                  <b-form-input
                    :disabled="true"
                    v-model.trim="affiliate.city"
                    rows="3"
                  ></b-form-input>
                </b-form-group>
              </b-colxx>
              <b-colxx sm="6">
                <b-form-group label="State">
                  <multiselect
                    :disabled="true"
                    v-model="selectedState"
                    :options="states"
                    :close-on-select="true"
                    placeholder="Select State"
                    track-by="stateID"
                    label="stateName"
                  ></multiselect>
                </b-form-group>
              </b-colxx>
            </b-row>
            <b-row>
              <b-colxx sm="6">
                <b-form-group label="Zip">
                  <b-form-input
                    :disabled="true"
                    v-model.trim="affiliate.zip"
                    rows="3"
                  ></b-form-input>
                </b-form-group>
              </b-colxx>
              <b-colxx sm="6">
                <b-form-group label="Website">
                  <b-form-input
                    :disabled="true"
                    v-model.trim="affiliate.webSite"
                    rows="3"
                  ></b-form-input>
                </b-form-group>
              </b-colxx>
            </b-row>
            <b-row>
              <b-colxx sm="6">
                <b-form-group label="Programs">
                  <multiselect
                    :disabled="true"
                    v-model="affiliate.programs"
                    :options="groupedPrograms"
                    :multiple="true"
                    group-values="options"
                    group-label="label"
                    track-by="programID"
                    label="dropdownLabel"
                    placeholder="Select Programs"
                    :close-on-select="false"
                  ></multiselect>
                </b-form-group>
              </b-colxx>
              <b-colxx sm="6">
                <b-form-group label="Operates In">
                  <multiselect
                    :disabled="true"
                    v-model="affiliate.states"
                    :options="states"
                    :multiple="true"
                    :close-on-select="false"
                    placeholder="Select States"
                    track-by="stateID"
                    label="stateName"
                    :custom-label="stateCustomLabel"
                  ></multiselect>
                </b-form-group>
              </b-colxx>
              <b-colxx sm="6">
                <b-form-group label="Approved Surveyors">
                  <multiselect
                    :disabled="true"
                    v-model="affiliate.approvedSurveyors"
                    :options="approvedSurveyors"
                    :multiple="true"
                    :close-on-select="false"
                    placeholder="Select Approved Surveyors"
                    track-by="id"
                    label="displayData"
                  ></multiselect>
                </b-form-group>
              </b-colxx>
            </b-row>
            <b-row>
              <b-colxx lg="6">
                <b-row>
                  <b-colxx lg="6">
                    <b-form-group label="Created Date">
                      <p>{{ formatDate(affiliate.dateCreated) }}</p>
                    </b-form-group>
                  </b-colxx>
                  <b-colxx lg="6">
                    <b-form-group label="Created By">
                      <p>{{ affiliate.createdBy }}</p>
                    </b-form-group>
                  </b-colxx>
                  <b-colxx lg="6">
                  <b-form-group label="Edited Date">
                      <p>{{ formatDate(affiliate.dateLastModified) }}</p>
                  </b-form-group>
                  </b-colxx>
                  <b-colxx lg="6">
                    <b-form-group label="Edited By">
                      <p>{{ affiliate.lastModifiedBy }}</p>
                    </b-form-group>
                  </b-colxx>
                </b-row>
              </b-colxx>
              <b-colxx lg="6">
                <b-row>
                  <b-colxx lg="6">
                    <b-form-group label="Contact Info">
                        <p v-html="affiliate.affiliateCompanyData.affiliateContactInfo"></p>
                    </b-form-group>
                  </b-colxx>
                  <b-colxx lg="6">
                    <b-form-group label="Discount Info">
                      <p v-html="affiliate.affiliateCompanyData.discountInfo"></p>
                    </b-form-group>
                  </b-colxx>
                </b-row>
              </b-colxx>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import Switches from 'vue-switches'
import AffiliateMixin from '../../mixins/AffiliateMixin.vue'
import ApplicationUserMixin from '../../mixins/ApplicationUserMixin.vue'
import CompanyMixin from '../../mixins/CompanyMixin.vue'
import CompanyAddressMixin from '../../mixins/CompanyAddressMixin.vue'
import Multiselect from 'vue-multiselect'
import ProgramMixin from '../../mixins/ProgramMixin.vue'
import moment from 'moment/moment.js'
import PhoneMixin from '../../mixins/PhoneMixin.vue'

export default {
  name: 'affiliateCompanyModal',
  components: {
    switches: Switches,
    multiselect: Multiselect
  },
  data () {
    return {
      newAffiliate: false,
      title: null,
      isVisible: false,
      affiliateID: null
    }
  },
  async created () {
    await this.getAllSalesMembers()
    await this.getAllProgramsByType()
    await this.getStates()
    await this.loadApprovedSurveyors()
    await this.getAllAffiliateTypes()
    this.mapAffiliateTypes()
  },
  mixins: [
    AffiliateMixin,
    ApplicationUserMixin,
    CompanyMixin,
    ProgramMixin,
    CompanyAddressMixin,
    PhoneMixin
  ],
  methods: {
    affiliateCustomLabel ({ affiliateTypeID }) {
      const affiliateTypes = this.affiliateTypes.find(
        affiliate => affiliate.affiliateTypeID === affiliateTypeID
      )
      return affiliateTypes ? affiliateTypes.affiliateTypeDescription : ''
    },
    stateCustomLabel ({ stateID }) {
      const states = this.states.find(states => states.stateID === stateID)
      return states ? states.stateName : ''
    },
    async loadApprovedSurveyors () {
      await this.getASPNetUsersByRole('Approved Surveyor')
      this.approvedSurveyors = this.mapASPNetUsers()
    },
    mapASPNetUsers () {
      return this.approvedSurveyors.map(u => {
        return {
          displayData: `${u.firstName} ${u.lastName}`,
          id: u.id
        }
      })
    },
    cancelAffiliatesForm () {
      this.$router.push({
        name: 'affiliates'
      })
    },
    formatDate (value) {
      if (value === null) {
        return ''
      } else {
        return moment(value, 'YYYY-MM-DD, hh:mm A').format('MM/DD/YYYY h:mm A')
      }
    },
    stateSelected (selectedOption) {
      this.affiliate.stateID = selectedOption.stateID
    },
    async show (opts = {}) {
      this.title = opts.title
      if (opts.affiliateID) {
        this.affiliateID = (opts.affiliateID)
        await this.getCompanyAffiliate(this.affiliateID).then(() => {
          if (this.affiliate.affiliateCompanyData.affiliateContactInfo) {
            this.affiliate.affiliateCompanyData.affiliateContactInfo = this.affiliate.affiliateCompanyData.affiliateContactInfo.replaceAll(';', ';<br>')
          }
          if (this.affiliate.affiliateCompanyData.discountInfo) {
            this.affiliate.affiliateCompanyData.discountInfo = this.affiliate.affiliateCompanyData.discountInfo.replaceAll(';', ';<br>')
          }
        })
      }
      console.log('visiblebelow')
      this.isVisible = true
    },
    mapAffiliateTypes () {
      this.affiliateTypes = this.affiliateTypes.map((a) => {
        return {
          affiliateTypeDescription: a.affiliateTypeDescription,
          affiliateTypeID: a.affiliateTypeID
        }
      })
    },
    close () {
      this.isVisible = false
    }
  },
  computed: {
    selectedState: {
      get () {
        return this.states.find(state => state.stateID === this.affiliate.stateID)
      },
      set () {
        this.states.stateID = this.affiliate.stateID
      }
    }
  }
}
</script>
