<template>
  <div>
    <confirmation ref="Confirmation"></confirmation>
    <affiliate-company-modal ref="AffiliateCompanyModal"></affiliate-company-modal>
    <b-form>
      <b-row>
        <b-colxx lg="3" md="6" sm="12">
          <multiselect
            v-model="affiliate.types"
            :options="affiliateTypes"
            :multiple="true"
            :close-on-select="false"
            placeholder="Select Affiliate Types"
            track-by="affiliateTypeID"
            :custom-label="affiliateTypeCustomLabel"
            @input="submitAffiliateTypes"
          ></multiselect>
        </b-colxx>
        <b-colxx lg="3" md="6" sm="12">
          <multiselect
            v-model="affiliate.affiliatesByType"
            :options="affiliates"
            :disabled="isAffiliatesDropdownDisabled"
            :multiple="true"
            :close-on-select="false"
            placeholder="Select Affiliates"
            track-by="affiliateID"
            :custom-label="affiliateCustomLabel"
          ></multiselect>
        </b-colxx>
        <b-colxx lg="4" md="6" sm="12">
          <b-button
            variant="success"
            size="lg"
            :disabled="isAddNewCompanyAffiliatesButtonDisabled"
            @click="submitNewCompanyAffiliates()"
            >Add Company Affiliates
          </b-button>
        </b-colxx>
      </b-row>
    </b-form>
    <datatable-heading
      :hasTitleSection="false"
      :changePageSize="changePageSize"
      :searchChange="_.debounce(searchChange, 500)"
      :from="from"
      :to="to"
      :total="total"
      :perPage="perPage"
      :titleAndButton="true"
    >
      <template v-slot:button>
        <div class="title-and-right-element">
          <PageTitle :title="'Affiliate'" :identifier="'Company #'+ companyID"></PageTitle>
          <div>
            <b-button
              v-b-modal.modalright
              variant="primary"
              size="md"
              style="font-size: 18px"
              class="simple-icon-trash"
              v-show="isTrashVisible"
              @click="deleteItems()"
            ></b-button>
          </div>
          </div>
      </template>
    </datatable-heading>
    <b-overlay :show="gridLoading" variant="transparent" blur="5px" opacity="1">
      <b-row>
        <b-colxx xxs="12">
          <b-card>
            <vuetable
              ref="vuetable"
              track-by="affiliateID"
              :api-url="apiBase"
              :query-params="makeQueryParams"
              :per-page="perPage"
              :reactive-api-url="false"
              :fields="fields"
              :css="css.table"
              pagination-path
              :row-class="onRowClass"
              @vuetable:pagination-data="onPaginationData"
              @vuetable:row-clicked="rowClicked"
              @vuetable:cell-rightclicked="rightClicked"
              @vuetable:load-error="handleLoadError"
              @vuetable:cell-clicked="cellclicked"
              @vuetable:loading="onVuetableLoading"
              @vuetable:loaded="onVuetableLoaded"
            >
              <template slot="affiliateContactInfo" slot-scope="props">
                <div @contextmenu="rightClicked(props.rowData, '', $event)">
                  <ReadMoreList separator=";" :linesToShow="1" :listText="props.rowData.affiliateContactInfo" />
                </div>
              </template>
              <template slot="affiliateContactName" slot-scope="props">
                <div @contextmenu="rightClicked(props.rowData, '', $event)">
                  <ReadMoreList separator=";" :linesToShow="1" :listText="affiliateContactInfoText(props.rowData.affiliateContactInfo, 'ContactName')" />
                </div>
              </template>
                <template slot="affiliateContactEmailAddress" slot-scope="props">
                  <div @contextmenu="rightClicked(props.rowData, '', $event)">
                    <ReadMoreList separator=";" :linesToShow="1" :listText="affiliateContactInfoText(props.rowData.affiliateContactInfo, 'ContactEmailAddress')" />
                  </div>
                </template>
              <template slot="discountInfo" slot-scope="props">
                <div @contextmenu="rightClicked(props.rowData, '', $event)">
                  <ReadMoreList separator=";" :linesToShow="1" :listText="props.rowData.discountInfo" />
                </div>
              </template>
            </vuetable>
          </b-card>
          <vuetable-pagination-bootstrap
            class="mt-4"
            ref="pagination"
            @vuetable-pagination:change-page="onChangePage"
          />
        </b-colxx>
      </b-row>
    </b-overlay>
    <v-contextmenu ref="contextmenu">
      <v-contextmenu-item @click="onContextMenuAction('view')">
        <i class="simple-icon-docs" />
        <span>View</span>
      </v-contextmenu-item>
      <v-contextmenu-item @click="onContextMenuAction('remove')">
        <i class="simple-icon-trash" />
        <span>Remove</span>
      </v-contextmenu-item>
    </v-contextmenu>
  </div>
</template>
<script>
import Confirmation from '../../../components/Common/Confirmation.vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from '../../../components/Common/VuetablePaginationBootstrap'
import { BeaconapiUrl, AffiliateContext } from '../../../constants/config'
import DatatableHeading from '../../../containers/datatable/DatatableHeading'
import CssTableConfig from '../../../constants/VuetableBootstrapconfig'
import AffiliateMixin from '../../../mixins/AffiliateMixin.vue'
import { mapMutations } from 'vuex'
import ReadMoreList from '../../../components/Listing/ReadMoreList.vue'
import bus from '../../../main'
import ParentEntityPageTitle from '../../../components/Common/ParentEntityPageTitle.vue'
import Multiselect from 'vue-multiselect'
import AffiliateCompanyModal from '../../../components/Modals/AffiliateCompanyModal.vue'

export default {
  props: {
    companyID: {
      type: Number,
      default: 0
    }
  },
  components: {
    Vuetable,
    'vuetable-pagination-bootstrap': VuetablePaginationBootstrap,
    'datatable-heading': DatatableHeading,
    Confirmation,
    ReadMoreList: ReadMoreList,
    PageTitle: ParentEntityPageTitle,
    Multiselect,
    'affiliate-company-modal': AffiliateCompanyModal
  },
  mixins: [AffiliateMixin],
  data () {
    var _idfield = 'affiliateID'
    const companyID = localStorage.getItem('companycontext')
    var currentOptionCount = 0
    return {
      isLoad: false,
      apiBase: `${BeaconapiUrl}/api/affiliate/company/${companyID}/table`,
      sort: '',
      page: 1,
      perPage: 10,
      search: '',
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      items: [],
      selectedItems: [],
      idfield: _idfield,
      css: CssTableConfig,
      gridLoading: false,
      showModal: false,
      modalText: '',
      fields: [
        {
          name: '__checkbox',
          title: '',
          titleClass: 'center aligned text-right',
          dataClass: 'center aligned text-right',
          width: '2%'
        },
        {
          name: _idfield,
          sortField: _idfield,
          title: 'Affiliate ID',
          titleClass: '',
          dataClass: 'text-muted',
          width: '8%'
        },
        {
          name: 'affiliateType',
          sortField: 'affiliateType',
          title: 'Affiliate Type(s)',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%'
        },
        {
          name: 'affiliateName',
          sortField: 'affiliateName',
          title: 'Affiliate Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%'
        },

        {
          name: 'affiliateCityState',
          sortField: 'affiliateCityState',
          title: 'Affiliate City, State',
          titleClass: '',
          dataClass: 'text-muted',
          width: '8%'
        },
        { name: '__slot:affiliateContactName', sortField: 'affiliateContactInfo', title: 'Affiliate Contact Name', titleClass: '', dataClass: 'text-muted', width: '15%' },
        { name: '__slot:affiliateContactEmailAddress', sortField: 'affiliateContactInfo', title: 'Affiliate Contact Email Address', titleClass: '', dataClass: 'text-muted', width: '15%' },
        {
          name: '__slot:discountInfo',
          sortField: 'discountInfo',
          title: 'Discount Info',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%'
        }
      ],
      json_data: []
    }
  },
  async created () {
    await this.getAllAffiliateTypes()
  },
  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'appendInfoMessage'
    ]),
    makeQueryParams (sortOrder, currentPage, perPage) {
      this.selectedItems = []
      return sortOrder[0]
        ? {
          sort: sortOrder[0]
            ? sortOrder[0].field + '|' + sortOrder[0].direction
            : '',
          page: currentPage,
          per_page: this.perPage,
          search: this.search
        }
        : {
          page: currentPage,
          per_page: this.perPage,
          search: this.search
        }
    },
    onVuetableLoading () {
      this.gridLoading = true
    },
    onVuetableLoaded () {
      this.gridLoading = false
    },
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.items = paginationData.data
      this.$refs.pagination.setPaginationData(paginationData)
      this.json_data = paginationData.data
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    changePageSize (perPage) {
      this.perPage = perPage
      this.refreshVueTable()
    },
    searchChange (val) {
      this.search = val
      this.refreshVueTable()
    },
    async deleteItems (record) {
      let message = ''; let title = 'Remove Affiliate'
      if (record) {
        message = `Are you sure you want to delete ${record}?`
      } else {
        if (this.$refs.vuetable.selectedTo.length > 1) {
          title = 'Remove Affiliates'
          message = 'Are you sure you want to delete these records?'
        } else {
          const matchRecord = this.$refs.vuetable.tableData.find(({ affiliateID }) => affiliateID === this.$refs.vuetable.selectedTo[0])
          message = `Are you sure you want to delete ${matchRecord.affiliateName}?`
        }
      }
      this.$refs.Confirmation.show({
        title: title,
        message: message,
        okButton: 'Remove'
      }).then(result => {
        if (result) {
          this.$refs.vuetable.selectedTo.forEach(async item => {
            this.setInfoMessage('')
            await this.removeCompanyAffiliate(this.companyID, item)
            if (this.statusCode === 200) {
              this.refreshVueTable()
            }
          })
        }
      })
    },
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    onRowClass (dataItem, index) {
      var outputclass = ''
      if (this.selectedItems.includes(dataItem[this.$data.idfield])) {
        outputclass = 'selected'
      }
      return outputclass
    },
    cellclicked (dataitem, field, event) {},
    rightClicked (dataItem, field, event) {
      event.preventDefault()
      this.rightClickedItem = dataItem
      const itemId = dataItem[this.$data.idfield]
      if (!this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = [itemId]
      }
      this.$refs.contextmenu.show({ top: event.pageY, left: event.pageX })
      this.selectedItems = this.$refs.vuetable.selectedTo
    },
    rowClicked (dataItem, event) {
      const itemId = dataItem[this.$data.idfield]

      if (this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = this.$refs.vuetable.selectedTo.filter(
          x => x !== itemId
        )
        localStorage.setItem(AffiliateContext, null)
      } else {
        this.$refs.vuetable.selectedTo.pop()
        this.$refs.vuetable.selectedTo.push(itemId)
        localStorage.setItem(AffiliateContext, itemId)
        bus.$emit('buildaffiliatecontext')
        bus.$emit('openaffiliatesubmenu')
      }

      this.selectedItems = this.$refs.vuetable.selectedTo
    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    },
    onContextMenuAction (action) {
      var matchedItems = this.$refs.vuetable.tableData.filter(item =>
        this.selectedItems.includes(item.affiliateID)
      )
      var affiliateNames = matchedItems
        .map(affiliate => affiliate.affiliateName)
        .join(', ')
      switch (action) {
        case 'view': {
          if (this.selectedItems.length > 1) {
            this.$refs.Confirmation.show({
              title: 'Error',
              message: 'You may only view one affiliate at a time',
              okButton: 'Cancel',
              exitFunction: e => {
                if (['headerclose', 'esc', 'backdrop'].includes(e.trigger)) {
                  this.refreshVueTable()
                }
              }
            })
          } else {
            this.$refs.AffiliateCompanyModal.show({
              title: `Affiliate ID: ${this.selectedItems[0]}`,
              affiliateID: this.selectedItems[0],
              exitFunction: () => {}
            })
          }
          break
        }
        case 'remove': {
          this.deleteItems(affiliateNames)
          break
        }
        default:
          break
      }
    },
    affiliateTypeCustomLabel ({ affiliateTypeID }) {
      const affiliateTypes = this.affiliateTypes.find(
        affiliate => affiliate.affiliateTypeID === affiliateTypeID
      )
      return affiliateTypes ? affiliateTypes.affiliateTypeDescription : ''
    },
    affiliateCustomLabel ({ affiliateID }) {
      const selectedAffiliates = this.affiliates.find(
        selectedAffiliate => selectedAffiliate.affiliateID === affiliateID
      )
      return selectedAffiliates ? selectedAffiliates.companyName : ''
    },
    async submitAffiliateTypes (option) {
      if (option.length < this.currentOptionCount) {
        this.affiliate.affiliatesByType = undefined
      }
      await this.getAffiliatesByTypes(this.affiliate.types, this.companyID)
      this.currentOptionCount = this.affiliate.types.length
    },
    async submitNewCompanyAffiliates () {
      await this.addCompanyAffiliates(this.affiliate.affiliatesByType, this.companyID)
      this.affiliate.affiliatesByType = undefined
      this.affiliate.types = undefined
      this.refreshVueTable()
    },
    affiliateContactInfoText (contactInfo, columnName) {
      if (contactInfo) {
        const info = contactInfo.split(', ')
        if (columnName === 'ContactName') {
          return info.slice(0, info.length - 1).join(', ')
        } else {
          return info[info.length - 1]
        }
      }
      return ''
    }
  },
  computed: {
    isTrashVisible () {
      return this.selectedItems.length > 0
    },
    isAffiliatesDropdownDisabled () {
      return this.affiliate.types === undefined || this.affiliate.types.length === 0
    },
    isAddNewCompanyAffiliatesButtonDisabled () {
      return this.affiliate.affiliatesByType === undefined || this.affiliate.affiliatesByType.length === 0
    }
  }
}
</script>
